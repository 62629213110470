import React from 'react'
import Layout from '../components/layouts/page'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'

const Vehicles = () => {
	return (
		<div className="page">
		<Helmet>
				<html lang="en" />
				<meta charSet="utf-8" />
				<title>The Vehicles in Our Fleet of Limos - Devine Ride</title>
		<meta name="description" content="Find the next limo for your wedding transportation, corporate travel, and shuttle needs."/>
		</Helmet>
		<Layout>
			<section className='container-xxl border-top'></section>
			<nav className="navbar sticky-top navbar-expand-lg navbar-dark bg-dark">
				<div className="container-xxl">
					<div>
						<span><a href='/' className='text-decoration-none text-light fs-5 me-1'>Home</a><i className="bi bi-chevron-right text-light me-1"></i></span>
						<a href='/vehicles' className='text-decoration-none text-light fs-5'>Vehicles</a>
					</div>
					<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<i className="bi bi-chevron-down"></i>
					</button>
					<div className="collapse navbar-collapse d-lg-flex justify-content-end" id="navbarNav">
						<ul className="navbar-nav">
							<li className="nav-item">
								<a className="nav-link text-light" href="#road-king">Road King</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="#corporate-shuttle">Corporate Shuttle</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="#night-rider">Night Rider</a>
							</li>
							<li className="nav-item">
								<a className="nav-link text-light" href="#weekender">Weekender</a>
							</li>
						</ul>
					</div>
				</div>
			</nav>
			<section className='bg-light'>
        <div className='container-xxl'>
          <div className='d-flex justify-content-center'>
            <div className='col-md-10 text-center py-5'>
              <div className='py-5'>
                <h1 className='display-1 fw-medium'>Vehicles for every occassion</h1>
              </div>
            </div>
          </div>
        </div>
				{/* <div className='bg-light-white mb-5'>
					<div className='container-xxl'>
						<StaticImage 
							src='../images/southern-california-limo.jpg' 
							alt='A limo driving through downtown Los Angeles'
						/>
					</div>
				</div> */}
      </section>
			<section className='py-5' id="road-king">
				<div className='container-xxl py-5'>
					<div className='d-flex justify-content-center text-center mb-5'>
						<div className='col-xl-6 col-lg-8'>
							<h2 className='display-2 fw-medium'>The Road King</h2>
							<a href='/quote' className='fs-5'>Reserve the Road King</a>
						</div>
					</div>
					<div className='row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4'>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/road-king/private-limo-orange-county.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Private jet seating</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/road-king/limo-wet-bar.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Refreshment center</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/road-king/j-lounge.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>J lounge</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/road-king/audio-visual.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Media center</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/road-king/reclining-seats.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Reclining captain's chairs</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='container-xxl border-top'></section>
			<section className='py-5' id="corporate-shuttle">
				<div className='container-xxl py-5'>
					<div className='d-flex justify-content-center text-center mb-5'>
						<div className='col-xl-6 col-lg-8'>
							<h2 className='display-2 fw-medium'>Corporate Shuttle</h2>
							<a href='/quote' className='fs-5'>Reserve the Corporate Shuttle</a>
						</div>
					</div>
					<div className='row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4'>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/corporate/corporate-shuttle.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Corporate seating</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/corporate/corporate-cargo.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Plenty of luggage room</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/corporate/corporate-headspace.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Spacious headroom</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/corporate/corporate-professional.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Corporate style sprinter</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/corporate/corporate-view.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Central air conditioning</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='container-xxl border-top'></section>
			<section className='py-5' id="night-rider">
				<div className='container-xxl py-5'>
					<div className='d-flex justify-content-center text-center mb-5'>
						<div className='col-xl-6 col-lg-8'>
							<h2 className='display-2 fw-medium'>The Night Rider</h2>
							<a href='/quote' className='fs-5'>Reserve the Night Rider</a>
						</div>
					</div>
					<div className='row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4'>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/night/night-los-angeles.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Double J seating</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/night/night-one.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Limo lighting</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/night/night-three.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Party atmosphere</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/night/night-four.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Limo coach</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/night/night-five.jpg' 
									alt='Arriving at a concert in style'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Faux ostrich leather</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className='container-xxl border-top'></section>
			<section className='py-5' id="weekender">
				<div className='container-xxl py-5'>
					<div className='d-flex justify-content-center text-center mb-5'>
						<div className='col-xl-6 col-lg-8'>
							<h2 className='display-2 fw-medium'>The Weekender</h2>
							<p className='fs-5'>Our Southern California limos are available 24 hours a day, 7 days a week for any celebration or corporate event.</p>
							<a href='/quote' className='fs-5'>Reserve the Weekender</a>
						</div>
					</div>
					<div className='row row-cols-1 row-cols-lg-3 row-cols-md-2 g-4'>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/weekender/weekender-los-angeles.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Comfortable captain's chairs with desk</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/weekender/weekender-controls.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Media and climate control</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/weekender/weekender-drinks.jpg' 
									alt='Business and corporate transportation'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>The fun center</p>
								</div>
							</div>
						</div>
						<div>
							<div className='card rounded border-0'>
								<StaticImage 
									src='../images/weekender/weekender-spacious.jpg' 
									alt='Arriving at a concert in style'
									className='card-img-top w-100'
								/>
								<div className='card-body'>
									<p className='card-title fs-5 fw-bold'>Spacious headroom</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
    </Layout>
		</div>
  )
}

export default Vehicles